import React from 'react'
import user1 from '../../assets/images/user1.jpg'
import user2 from '../../assets/images/lehoangson.jpg'
import user3 from '../../assets/images/user3.jpg'
import shape9 from '../../assets/images/shape/vector-shape9.png'
import shape10 from '../../assets/images/shape/vector-shape10.png'
import Loadable from '@loadable/component'
const OwlCarousel = Loadable(() => import('react-owl-carousel3'))

const options = {
    loop: true,
    nav: true,
    dots: false,
    autoplayHoverPause: true,
    autoplay: true,
    margin: 30,
    navText: [
        "<i class='flaticon-left-1'></i>",
        "<i class='flaticon-right-1'></i>"
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 2,
        },
        992: {
            items: 2,
        }
    }
};

const Testimonials = () => {
    const [display, setDisplay] = React.useState(false);

    React.useEffect(() => {
        setDisplay(true);
    }, [])

    return (
        <section className="testimonials-area bg-23173a pt-100 pb-70">
            <div className="container">
                <div className="section-title">
                    <h2>What Our Clients are Saying?</h2>
                    <p>We pride ourselves on providing an exceptional service to our clients, but you don’t just have to take our word for it. Read what our clients have to say about working with us.</p>
                </div>

                {display ? <OwlCarousel 
                    className="testimonials-slides owl-carousel owl-theme"
                    {...options}
                > 
                    <div className="single-testimonials-box">
                        <img src={user1} className="shadow-sm" alt="testimonials" />
                        <p>"About GritBros? It’s not just about tech whizzes and programming, this is a firm that cares about you, the client. Great staff, knowledgeable and courteous that makes you feel valued. They are true partners and I highly recommend you their service."</p>

                        <div className="client-info">
                            <div className="title">
                                <h3>Hoang Tran Minh</h3>
                                <span>Manager at Thu Cuc Beauty</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <img src={user2} className="shadow-sm" alt="testimonials" />
                        <p>"As a small business, I need a modern E-commerce website that I can easily manage, and represents my business well enough. GritBros knows my business and provides an excellent service. When I phone them, they understands my query straightaway."</p>

                        <div className="client-info">
                            <div className="title">
                                <h3>Le Hoang Son</h3>
                                <span>CEO at Khói Store</span>
                            </div>
                        </div>
                    </div>

                    <div className="single-testimonials-box">
                        <img src={user3} className="shadow-sm" alt="testimonials" />
                        <p>"Through the ups and downs of almost 10 years in business, with more than one company, I would never use anyone else's outsourcing service other than Gritbros. They are always ready with solutions – for all kinds of software problems"</p>

                        <div className="client-info">
                            <div className="title">
                                <h3>Tran Hien</h3>
                                <span>CEO at EOS.VN</span>
                            </div>
                        </div>
                    </div>
                </OwlCarousel> : ''}
            </div>

            <div className="vector-shape9">
                <img src={shape9} alt="testimonials" />
            </div>
            <div className="vector-shape10">
                <img src={shape10} alt="testimonials" />
            </div>
        </section>
    )
}

export default Testimonials